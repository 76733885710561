const reduceMiddlewares = (modules) => (
  Object.keys(modules).reduce((handlers, module) => {
    const { middlewares } = modules[module]
    if (!middlewares) return handlers

    Object.keys(middlewares).forEach((mutation) => {
      if (!handlers[mutation]) handlers[mutation] = []
      handlers[mutation].push({ module, handler: middlewares[mutation] })
    })

    return handlers
  }, {})
)

export default (modules) => {
  const middlewares = reduceMiddlewares(modules)

  return (store) => (
    store.subscribe((mutation, state) => {
      if (Object.prototype.hasOwnProperty.call(middlewares, mutation.type)) {
        middlewares[mutation.type].forEach((middleware) => (
          middleware.handler(store, mutation, state[middleware.module], state)
        ))
      }
    })
  )
}
