import { requests } from '@/plugins/Amplify'

import {
  STATUS_SAVED,
  STATUS_ERROR,
  STATUS_LOADED,
  STATUS_LOADING,
  MUTATION_USER_LOGOUT
} from '@/constants'

const LEARN_PAGES_LOADING = 'LEARN_PAGES_LOADING'
const LEARN_PAGES_SEARCH_ERROR = 'LEARN_PAGES_SEARCH_ERROR'
const LEARN_PAGES_SEARCH_SUCCESS = 'LEARN_PAGES_SEARCH_SUCCESS'
const LEARN_PAGES_ERROR_SUCCESS = 'LEARN_PAGES_ERROR_SUCCESS'
const LEARN_PAGES_SAVED_SUCCESS = 'LEARN_PAGES_SAVED_SUCCESS'
const LEARN_PAGES_LOADED_SUCCESS = 'LEARN_PAGES_LOADED_SUCCESS'

const resetStore = () => ({
  row: {},
  rows: [],
  count: 0,
  params: null,
  status: null
})

export default {
  state: resetStore(),

  actions: {
    searchLearnPages: async ({ commit }, params) => {
      commit(LEARN_PAGES_LOADING, params)

      try {
        const data = await requests.getLearnPages({ ...params, limit: 12 })
        commit(LEARN_PAGES_SEARCH_SUCCESS, data)
      } catch (err) {
        console.log(err)
        commit(LEARN_PAGES_SEARCH_ERROR, err)
      }
    },

    loadLearnPage: async ({ commit }, { id }) => {
      commit(LEARN_PAGES_LOADING)

      try {
        const learnPage = await requests.getLearnPage(id)
        commit(LEARN_PAGES_LOADED_SUCCESS, learnPage)
      } catch (err) {
        console.log(err)
        commit(LEARN_PAGES_ERROR_SUCCESS, err)
      }
    },

    saveLearnPage: async ({ commit }, payload) => {
      commit(LEARN_PAGES_LOADING)

      try {
        const requestSave = payload.id ? requests.putLearnPage(payload.id, payload) : requests.postLearnPage(payload)
        const learnPage = await requestSave
        commit(LEARN_PAGES_SAVED_SUCCESS, learnPage)
      } catch (err) {
        console.log(err)
        commit(LEARN_PAGES_ERROR_SUCCESS, err)
      }
    }
  },

  mutations: {
    [LEARN_PAGES_LOADING]: (state, params) => {
      const newState = { status: STATUS_LOADING }
      if (params) newState.params = params
      Object.assign(state, newState)
    },

    [LEARN_PAGES_LOADED_SUCCESS]: (state, row) => {
      Object.assign(state, { row, status: STATUS_LOADED })
    },

    [LEARN_PAGES_SAVED_SUCCESS]: (state, row) => {
      Object.assign(state, { row, status: STATUS_SAVED })
    },

    [LEARN_PAGES_ERROR_SUCCESS]: (state, err) => {
      Object.assign(state, { row: { err } })
    },

    [LEARN_PAGES_SEARCH_SUCCESS]: (state, data) => {
      const { rows, count } = data
      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [LEARN_PAGES_SEARCH_ERROR]: (state) => {
      Object.assign(state, { status: STATUS_ERROR })
    },

    [MUTATION_USER_LOGOUT]: (state) => {
      Object.assign(state, resetStore())
    }
  }
}
