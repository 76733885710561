import store from '@/store'
import Api from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { ACTION_USER_LOGOUT } from '@/constants'

export const getHostAPI = () => {
  const { hostname } = window.location
  if (hostname.endsWith('nurimba')) return 'https://api.dmc.udv.org.br/v1'
  if (hostname.endsWith('udv.org.br')) return 'https://api.dmc.udv.org.br/v1'
  return process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : `https://api.${hostname}/v1`
}

Auth.configure({
  region: process.env.VUE_APP_REGION,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'CUSTOM_AUTH'
})

Api.configure({
  endpoints: [{
    name: 'api',
    endpoint: getHostAPI(),

    custom_header: () => {
      const token = localStorage.getItem('token') || ''
      return token ? { Authorization: `Bearer ${token}` } : ''
    }
  }]
})

export const requests = {
  getCores: (query) => Api.get('api', '/nucleos', { queryStringParameters: query }),
  getOradores: (query) => Api.get('api', '/oradores', { queryStringParameters: query }),
  getAlbums: (query) => Api.get('api', '/albums', { queryStringParameters: query }),

  getLearnPage: (id) => Api.get('api', `/learn-pages/${id}`),
  getLearnPages: (query) => Api.get('api', '/learn-pages', { queryStringParameters: query }),
  putLearnPage: (id, body) => Api.put('api', `/learn-pages/${id}`, { body }),
  postLearnPage: (body) => Api.post('api', '/learn-pages', { body }),

  getUpdate: (id) => Api.get('api', `/updates/${id}`),
  getUpdates: (query) => Api.get('api', '/updates', { queryStringParameters: query }),
  putUpdate: (id, body) => Api.put('api', `/updates/${id}`, { body }).catch(checkErrorUnauthorized),
  postUpdate: (body) => Api.post('api', '/updates', { body }).catch(checkErrorUnauthorized),
  deleteUpdate: (id) => Api.put('api', `/updates/remove/${id}`).catch(checkErrorUnauthorized),
  resendCode: (body) => Api.post('api', '/authentication/code', { body }),

  postAudio: (body) => Api.post('api', '/audios', { body }),
  getAudioUploadLink: ({ size }) => Api.get('api', '/audios/signedlink', { queryStringParameters: { length: size } }),
  getAudioStreamLink: ({ id }) => Api.get('api', `/audios/${id}/streamlink`),

  getAudio: (id) => Api.get('api', `/audios/${id}/metadata`).catch(checkErrorUnauthorized),
  getAudios: (query) => Api.get('api', '/audios', { queryStringParameters: query }).catch(checkErrorUnauthorized),
  setMetadata: (id, body) => Api.put('api', `/audios/${id}/metadata`, { body }).catch(checkErrorUnauthorized),
  deleteAudio: (id) => Api.put('api', `/audios/${id}/remove`).catch(checkErrorUnauthorized),

  addUser: (body) => Api.post('api', '/users', { body }),
  getUsers: (query) => Api.get('api', '/users', { queryStringParameters: query }).catch(checkErrorUnauthorized),
  setUserProfile: (user, profile) => Api.post('api', `/users/${user}/profile/${profile}`),
  removeUserProfile: (user, profile) => Api.put('api', `/users/${user}/profile/${profile}`),

  getCubeFilters: () => Api.get('api', '/etl/v1/filters'),
  refreshAppToken: () => Api.get('api', '/refresh')
}

export const cognito = () => Auth

const checkErrorUnauthorized = (err) => {
  const hasErrorAPI = Boolean(err && err.response && err.response.data && err.response.data.error)
  if (hasErrorAPI) return store.dispatch(ACTION_USER_LOGOUT, err.response.data.error)
  throw err
}
