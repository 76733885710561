import { requests, cognito } from '@/plugins/Amplify'
import router from '@/router'

import {
  ACTION_USER_MFA,
  ACTION_USER_CODE,
  ACTION_USER_LOGIN,
  ACTION_USER_FORGOT,
  ACTION_USER_CREATE,
  ACTION_USER_LOGOUT,
  ACTION_USER_RECOVERY,
  ACTION_USER_FORCE_NEW_PASSWORD,

  MUTATION_ROUTE,
  MUTATION_RESEND_CODE,
  MUTATION_USER_LOGOUT,

  ROUTE_AUDIOS_APPROVED,

  ROUTE_AUTH_MFA,
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_INVITE,
  ROUTE_AUTH_FORGOT,
  ROUTE_AUTH_FORCE_NEW_PASSWORD

  // ACTION_AUDIO_OPEN_EVENTS
} from '@/constants'

const MUTATION_USER_CREATE = 'MUTATION_USER_CREATE'
const MUTATION_USER_LOGIN = 'MUTATION_USER_LOGIN'
const MUTATION_USER_LOADING = 'MUTATION_USER_LOADING'
const MUTATION_USER_RECOVERY = 'MUTATION_USER_RECOVERY'

const MUTATION_COGNITO_ERROR = 'MUTATION_COGNITO_ERROR'
const MUTATION_AFFILIATE_ERROR = 'MUTATION_AFFILIATE_ERROR'

const confirmSMS = (session) => session != null && session.challengeName === 'SMS_MFA'
const isCustomChallenge = (session) => session != null && session.challengeName === 'CUSTOM_CHALLENGE'
const forceNewPassword = (session) => session != null && session.challengeName === 'NEW_PASSWORD_REQUIRED'

const resetState = () => ({
  info: null,
  error: null,
  loading: false,
  userCognito: null,
  credentials: {}
})

const loadApiToken = async (context, { idToken }) => {
  localStorage.setItem('token', idToken.jwtToken)
  const res = await requests.refreshAppToken()
  localStorage.setItem('token', res.token)
  await cognito().signOut().catch((err) => console.log({ err }))
  // context.dispatch(ACTION_AUDIO_OPEN_EVENTS)
  router.push({ name: ROUTE_AUDIOS_APPROVED })
}

export default {
  state: resetState(),

  actions: {
    [ACTION_USER_LOGIN]: async (context, { username, password }) => {
      localStorage.clear()
      context.commit(MUTATION_USER_LOADING)

      try {
        const userCognito = await cognito().signIn(username, password)
        if (userCognito.preferredMFA !== 'NOMFA') return context.commit(MUTATION_USER_LOGIN, userCognito)
        if (userCognito.challengeName === 'CUSTOM_CHALLENGE') return context.commit(MUTATION_USER_LOGIN, userCognito)
        await loadApiToken(context, userCognito.signInUserSession)
      } catch (error) {
        context.commit(MUTATION_COGNITO_ERROR, error)
      }
    },

    [ACTION_USER_MFA]: async (context, { code }) => {
      context.commit(MUTATION_USER_LOADING)

      try {
        const userCognito = await cognito().sendCustomChallengeAnswer(context.state.userCognito, code)
        if (!userCognito?.signInUserSession) {
          context.commit(MUTATION_COGNITO_ERROR, { message: 'Código não confere' })
          return
        }
        await loadApiToken(context, userCognito.signInUserSession)
      } catch (error) {
        context.commit(MUTATION_COGNITO_ERROR, error)
      }
    },

    [ACTION_USER_CODE]: async (context, { type }) => {
      try {
        const { challengeParam } = context.state.userCognito
        const phone = challengeParam.CODE_DELIVERY_DESTINATION
        const device = process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID
        const principalId = challengeParam.principalId
        await requests.resendCode({ type, phone, principalId, device })
        context.commit(MUTATION_RESEND_CODE)
      } catch (error) {
        context.commit(MUTATION_COGNITO_ERROR, error)
      }
    },

    [ACTION_USER_LOGOUT]: ({ commit }, error) => {
      localStorage.clear()
      commit(MUTATION_USER_LOGOUT, error)
    },

    [ACTION_USER_FORCE_NEW_PASSWORD]: async ({ state, commit, dispatch }, { password }) => {
      commit(MUTATION_USER_LOADING)

      try {
        const { username } = state.userCognito
        await cognito().completeNewPassword(state.userCognito, password)
        await cognito().signOut().catch(err => console.err(err))
        dispatch(ACTION_USER_LOGIN, { username, password })
      } catch (error) {
        commit(MUTATION_COGNITO_ERROR, error)
      }
    },

    [ACTION_USER_CREATE]: async ({ commit }, credentials) => {
      commit(MUTATION_USER_LOADING)

      try {
        const nucleoId = String(credentials.nucleoId)
        const { username } = await requests.addUser({ ...credentials, nucleoId })
        commit(MUTATION_USER_CREATE, { username })
      } catch (error) {
        commit(MUTATION_AFFILIATE_ERROR, error)
      }
    },

    [ACTION_USER_RECOVERY]: async ({ commit }, username) => {
      commit(MUTATION_USER_LOADING)

      try {
        await cognito().forgotPassword(username)
        commit(MUTATION_USER_RECOVERY, { username })
      } catch (error) {
        commit(MUTATION_COGNITO_ERROR, error)
      }
    },

    [ACTION_USER_FORGOT]: async ({ commit, dispatch }, { username, code, password }) => {
      commit(MUTATION_USER_LOADING)

      try {
        await cognito().forgotPasswordSubmit(username, code, password)
        await cognito().signOut().catch(err => console.err(err))
        dispatch(ACTION_USER_LOGIN, { username, password })
      } catch (error) {
        commit(MUTATION_COGNITO_ERROR, error)
      }
    }
  },

  mutations: {
    [MUTATION_ROUTE]: () => {},

    [MUTATION_USER_LOGOUT]: (state, error) => {
      Object.assign(state, resetState(), error ? { error } : {})
    },

    [MUTATION_USER_LOADING]: (state) => {
      Object.assign(state, {
        info: null,
        error: null,
        loading: true
      })
    },

    [MUTATION_COGNITO_ERROR]: (state, error) => {
      Object.assign(state, {
        loading: false,
        error: error.message
      })
    },

    [MUTATION_USER_LOGIN]: (state, userCognito) => {
      Object.assign(state, { ...resetState(), userCognito })
    },

    [MUTATION_USER_CREATE]: (state) => {
      Object.assign(state, {
        error: null,
        loading: false,
        info: 'sended email with temporary password'
      })
    },

    [MUTATION_RESEND_CODE]: (state, msg) => {
      Object.assign(state, {
        error: null,
        info: 'resended code to the phone'
      })
    },

    [MUTATION_USER_RECOVERY]: (state) => {
      Object.assign(state, {
        error: null,
        loading: false,
        info: 'sended email with temporary password'
      })
    },

    [MUTATION_AFFILIATE_ERROR]: (state, error) => {
      const hasMsg = error && error.response && error.response.data && error.response.data.error
      const errMsg = hasMsg ? error.response.data.error : 'internal error'
      Object.assign(state, { loading: false, error: errMsg })
    }
  },

  middlewares: {
    [MUTATION_USER_LOGIN]: (_, { payload }) => {
      if (confirmSMS(payload)) router.push({ name: ROUTE_AUTH_MFA })
      if (isCustomChallenge(payload)) router.push({ name: ROUTE_AUTH_MFA })
      if (forceNewPassword(payload)) router.push({ name: ROUTE_AUTH_FORCE_NEW_PASSWORD })
    },

    [MUTATION_USER_CREATE]: (_, { payload }) => {
      router.push({ name: ROUTE_AUTH_INVITE, query: payload })
    },

    [MUTATION_USER_RECOVERY]: (_, { payload }) => {
      router.push({ name: ROUTE_AUTH_FORGOT, query: payload })
    },

    [MUTATION_USER_LOGOUT]: () => {
      router.push({ name: ROUTE_AUTH_LOGIN })
    }
  }
}
