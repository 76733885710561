import { requests } from '@/plugins/Amplify'

import {
  STATUS_ERROR,
  STATUS_LOADED,
  STATUS_LOADING,
  ACTION_AUDIO_LIST,
  MUTATION_USER_LOGOUT,
  ACTION_AUDIO_LIST_CLEAR
} from '@/constants'

const MUTATION_AUDIO_LIST_CLEAR = 'MUTATION_AUDIO_LIST_CLEAR'
const MUTATION_AUDIO_LIST_ERROR = 'MUTATION_AUDIO_LIST_ERROR'
const MUTATION_AUDIO_LIST_PENDING = 'MUTATION_AUDIO_LIST_PENDING'
const MUTATION_AUDIO_LIST_SUCCESS = 'MUTATION_AUDIO_LIST_SUCCESS'

const resetStore = () => ({
  rows: [],
  count: 0,
  status: null
})

export default {
  state: resetStore(),

  actions: {
    [ACTION_AUDIO_LIST_CLEAR]: () => {},

    [ACTION_AUDIO_LIST]: ({ commit }, params) => {
      commit(MUTATION_AUDIO_LIST_PENDING)

      requests.getAudios(params)
        .then((data) => commit(MUTATION_AUDIO_LIST_SUCCESS, data))
        .catch(error => commit(MUTATION_AUDIO_LIST_ERROR, error))
    }
  },

  mutations: {
    [MUTATION_AUDIO_LIST_CLEAR]: (state) => {
      Object.assign(state, resetStore())
    },

    [MUTATION_AUDIO_LIST_PENDING]: (state) => {
      Object.assign(state, { ...resetStore(), status: STATUS_LOADING })
    },

    [MUTATION_AUDIO_LIST_SUCCESS]: (state, result) => {
      const { rows, count } = result
      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [MUTATION_AUDIO_LIST_ERROR]: (state) => {
      Object.assign(state, { status: STATUS_ERROR })
    },

    [MUTATION_USER_LOGOUT]: (state) => {
      Object.assign(state, resetStore())
    }
  }
}
