<template>
  <v-footer
    v-if="stream && stream.url"
    app
    class="ma-0 pa-0"
    style="z-index: 9999 !important; border-top: 1px solid #ddd !important; background-color: #fff"
  >
    <v-spacer />
    <aplayer
      ref="aplayer"
      :music="music"
      :autoplay="stream.autoPlay"
      @play="onPlay"
      @error="onError"
      @ended="onEnded"
      @pause="onPause"
    />
    <v-btn
      small
      icon
      @click="removeFooter"
    >
      <v-icon color="red lighten-1">
        mdi-close-circle-outline
      </v-icon>
    </v-btn>
    <v-spacer />
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
import { requests } from '@/plugins/Amplify'
import aplayer from 'vue-aplayer'

import {
  ACTION_AUDIO_ENDED,
  ACTION_AUDIO_PAUSE,
  ACTION_AUDIO_LISTEN
} from '@/constants'

export default {
  components: {
    aplayer
  },

  props: {
    token: {},
    session: {}
  },

  data () {
    return {
      stream: {}
    }
  },

  computed: {
    ...mapState({
      audioPlay: ({ searchAudios }) => searchAudios.stream
    }),

    music () {
      if (!this.stream?.url) return null
      const { artist, name: title, url: src } = this.stream
      return { src, title, artist }
    }
  },

  methods: {
    removeFooter () {
      if (this.$refs.aplayer) this.$refs.aplayer.pause()
      setTimeout(() => (this.stream = {}), 250)
    },

    onPause () {
      if (!this.stream.id) return
      if (!this.stream.url) return
      if (this.stream.action === 'PAUSE') return
      if (this.stream.action === 'ENDED') return
      this.$store.dispatch(ACTION_AUDIO_PAUSE)
    },

    onPlay (e) {
      if (!this.stream.id) return
      if (!this.stream.url) return
      if (this.stream.action === 'PLAY') return
      if (this.stream.action === 'ENDED') return
      this.$store.dispatch(ACTION_AUDIO_LISTEN, this.stream)
    },

    onEnded (e) {
      if (!this.stream.id) return
      if (!this.stream.url) return
      if (this.stream.action === 'ENDED') return
      this.$store.dispatch(ACTION_AUDIO_ENDED)
    },

    onError (e) {
      if (!this.stream.id) return
      if (!this.stream.url) return
      if (this.stream.action === 'ENDED') return
      this.$store.dispatch(ACTION_AUDIO_ENDED)
    },

    getAutoPlay () {
      return this.session ? !this.session.administrador : false
    },

    async makePlayerData (audio) {
      if (!audio.id) return {}
      if (!this.token) return {}

      const { url } = await requests.getAudioStreamLink(audio)

      return {
        ...audio,
        url,
        name: audio.assunto,
        artist: `${audio.oradorNome} | Grau: ${audio.grauSigla}`,
        autoPlay: this.getAutoPlay()
      }
    }
  },

  watch: {
    audioPlay (audio) {
      if (!audio) return

      const { id, action } = audio
      this.stream.action = action
      if (action === 'ENDED') return

      const { media } = { ...this.$refs.aplayer }
      const isPaused = media && media.paused
      if (action === 'PAUSE' && isPaused) return
      if (action === 'PAUSE' && media) return this.$refs.aplayer.pause()

      const isPlaying = media && !media.paused
      if (action === 'PLAY' && isPlaying && this.stream.id === id) return
      if (action === 'PLAY' && !isPlaying && this.stream.id === id) return this.$refs.aplayer.play()

      this.stream.action = 'PAUSE'
      if (this.$refs.aplayer) this.$refs.aplayer.pause()

      this.$nextTick(async () => {
        if (this.stream?.url) this.stream.url = ''
        this.stream = await this.makePlayerData(audio)
      })
    }
  }
}
</script>
