import { requests } from '@/plugins/Amplify'

import {
  STATUS_ERROR,
  STATUS_LOADED,
  STATUS_LOADING,
  ACTION_USERS_SEARCH,
  MUTATION_USER_LOGOUT
} from '@/constants'

const MUTATION_USERS_ERROR = 'MUTATION_USERS_ERROR'
const MUTATION_USERS_LOADING = 'MUTATION_USERS_LOADING'
const MUTATION_USERS_SUCCESS = 'MUTATION_USERS_SUCCESS'

const resetStore = () => ({
  rows: [],
  count: 0,
  params: null,
  status: null
})

export default {
  state: resetStore(),

  actions: {
    [ACTION_USERS_SEARCH]: async ({ commit }, params) => {
      commit(MUTATION_USERS_LOADING, params)

      try {
        const data = await requests.getUsers({ ...params, limit: 12 })
        commit(MUTATION_USERS_SUCCESS, data)
      } catch (error) {
        commit(MUTATION_USERS_ERROR, error)
      }
    }
  },

  mutations: {
    [MUTATION_USERS_LOADING]: (state, params) => {
      Object.assign(state, { params, status: STATUS_LOADING })
    },

    [MUTATION_USERS_SUCCESS]: (state, data) => {
      const { rows, count } = data
      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [MUTATION_USERS_ERROR]: (state) => {
      Object.assign(state, { status: STATUS_ERROR })
    },

    [MUTATION_USER_LOGOUT]: (state) => {
      Object.assign(state, resetStore())
    }
  }
}
