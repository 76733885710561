import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

Vue.mixin({
  methods: {
    i18n: (str) => String(str).translate()
  }
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },

    themes: {
      light: {
        primary: '#0094d9',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },

  lang: {
    locales: { pt },
    current: 'pt'
  }
})
