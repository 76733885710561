<template>
  <v-app>
    <v-app-bar
      color="primary"
      dense
      fixed
      dark
      app
      flat
      :height="50"
    >
      <v-spacer />

      <v-toolbar-title>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <span>
              <v-avatar :size="47">
                <img
                  src="/img/icons/android-icon-96x96.png"
                  alt="DMC Vivo"
                  v-on="on"
                  style="background-color: #fff"
                >
              </v-avatar>
            </span>
          </template>
          <span>Departamento de Memória e comunicação</span>
        </v-tooltip>
      </v-toolbar-title>

      <v-spacer />

      <template>
        <v-toolbar-items style="position: absolute; right: 0">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                text
                v-on="on"
                style="text-transform: lowercase"
              >
                <small>v{{ version }}</small>
              </v-btn>
            </template>
            <span>{{ 'btn.version'.translate() }}</span>
          </v-tooltip>
        </v-toolbar-items>
      </template>
    </v-app-bar>

    <v-main>
      <v-container
        fluid
        style="margin-top: 70px"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm6
            md4
            lg3
            xl2
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view />
            </transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import pkg from '../../../package.json'

export default {
  data () {
    return {
      version: pkg.version
    }
  }
}
</script>
