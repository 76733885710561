import Auth from '@/views/Auth'

import {
  ROUTE_AUTH_MFA,
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_INVITE,
  ROUTE_AUTH_CREATE,
  ROUTE_AUTH_FORGOT,
  ROUTE_AUTH_RECOVERY,
  ROUTE_AUTH_FORCE_NEW_PASSWORD
} from '@/constants'

const Login = () => import(/* webpackChunkName: "auth-login" */ '../views/Auth/Login')
const MFA = () => import(/* webpackChunkName: "auth-mfa" */ '../views/Auth/MFA')
const PasswordInvite = () => import(/* webpackChunkName: "auth-passwordinvite" */ '../views/Auth/PasswordInvite')
const PasswordForceRenew = () => import(/* webpackChunkName: "auth-passwordforcerenew" */ '../views/Auth/PasswordForceRenew')
const Create = () => import(/* webpackChunkName: "auth-create" */ '../views/Auth/Create')
const Recovery = () => import(/* webpackChunkName: "auth-recovery" */ '../views/Auth/Recovery')
const PasswordForgot = () => import(/* webpackChunkName: "auth-passwordforgot" */ '../views/Auth/PasswordForgot')

export default {
  path: '/acesso',
  component: Auth,
  children: [{
    path: '/acesso',
    name: ROUTE_AUTH_LOGIN,
    component: Login
  }, {
    path: '/acesso/segundo-fator-autenticacao',
    name: ROUTE_AUTH_MFA,
    component: MFA
  }, {
    path: '/acesso/confirmar-senha',
    name: ROUTE_AUTH_INVITE,
    component: PasswordInvite
  }, {
    path: '/acesso/definir-senha',
    name: ROUTE_AUTH_FORCE_NEW_PASSWORD,
    component: PasswordForceRenew
  }, {
    path: '/acesso/cadastrar',
    name: ROUTE_AUTH_CREATE,
    component: Create
  }, {
    path: '/acesso/recuperar-senha',
    name: ROUTE_AUTH_RECOVERY,
    component: Recovery
  }, {
    path: '/acesso/trocar-senha',
    name: ROUTE_AUTH_FORGOT,
    component: PasswordForgot
  }]
}
