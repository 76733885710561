import Dashboard from '@/views/Dashboard'

import {
  ROUTE_LEARN_PAGES,
  ROUTE_USERS_SEARCH,

  ROUTE_AUDIOS_NEW,
  ROUTE_AUDIO_REVIEW,

  ROUTE_AUDIOS_APPROVED,
  ROUTE_AUDIOS_REVIEW,
  ROUTE_AUDIOS_REVIEW2,
  ROUTE_AUDIOS_APPROVING,
  ROUTE_AUDIOS_EXAM,

  ROUTE_LEARN_PAGES_NEW,
  ROUTE_LEARN_PAGES_EDIT,
  ROUTE_LEARN_PAGES_VIEW,
  ROUTE_LEARN_PAGES_TMP,

  ROUTE_UPDATES_NEW,
  ROUTE_UPDATES_LIST,
  ROUTE_UPDATES_EDIT
  // ROUTE_REPORT_AUDIOS,
  // ROUTE_REPORT_ACCESS,
  // ROUTE_REPORT_SEARCH,
  // ROUTE_REPORT_REVIEWS,
  // ROUTE_REPORT_NAVIGATION
} from '@/constants'

const UserSearch = () => import(/* webpackChunkName: "dash-usersearch" */ '../views/Dashboard/UserSearch')
const LearnPageForm = () => import(/* webpackChunkName: "dash-learnpageform" */ '../views/Dashboard/LearnPageForm')
const LearnPageView = () => import(/* webpackChunkName: "dash-learnpageform" */ '../views/Dashboard/LearnPageView')
const LearnPageSearch = () => import(/* webpackChunkName: "dash-learnpagesearch" */ '../views/Dashboard/LearnPageSearch')
// const AudiosReport = () => import(/* webpackChunkName: "audios-report" */ '../views/Reports/AudiosReport')
// const SearchReport = () => import(/* webpackChunkName: "search-report" */ '../views/Reports/SearchReport')
// const AccessReport = () => import(/* webpackChunkName: "access-report" */ '../views/Reports/AccessReport')
// const NavigationReport = () => import(/* webpackChunkName: "navigation-report" */ '../views/Reports/NavigationReport')
// const ReviewReport = () => import(/* webpackChunkName: "review-report" */ '../views/Reports/ReviewsReport')

const AudioForm = () => import(/* webpackChunkName: "dash-audioform" */ '../views/Dashboard/AudioForm')
const AudioReview = () => import(/* webpackChunkName: "dash-audioreview" */ '../views/Dashboard/AudioReview')
const AudioSearchApproved = () => import(/* webpackChunkName: "audio-approveds" */ '../views/Dashboard/AudioSearchApproved')
const AudioSearchApproving = () => import(/* webpackChunkName: "audio-approving" */ '../views/Dashboard/AudioSearchApproving')
const AudioSearchExam = () => import(/* webpackChunkName: "audio-exam" */ '../views/Dashboard/AudioSearchExam')
const AudioSearchReview = () => import(/* webpackChunkName: "audio-review" */ '../views/Dashboard/AudioSearchReview')
const AudioSearchReview2 = () => import(/* webpackChunkName: "audio-review2" */ '../views/Dashboard/AudioSearchReview2')

const UpdatesForm = () => import(/* webpackChunkName: "dash-updatesform" */ '../views/Dashboard/UpdatesForm')
const UpdatesList = () => import(/* webpackChunkName: "dash-updateslist" */ '../views/Dashboard/UpdatesList')

export default {
  path: '/',
  redirect: { name: ROUTE_AUDIOS_APPROVED },
  component: Dashboard,
  children: [{
    path: '/audios-aprovados',
    name: ROUTE_AUDIOS_APPROVED,
    component: AudioSearchApproved
  }, {
    path: '/aguardando-revisao',
    name: ROUTE_AUDIOS_REVIEW,
    component: AudioSearchReview
  }, {
    path: '/parcialmente-revisados',
    name: ROUTE_AUDIOS_REVIEW2,
    component: AudioSearchReview2
  }, {
    path: '/aguardando-aprovacao',
    name: ROUTE_AUDIOS_APPROVING,
    component: AudioSearchApproving
  }, {
    path: '/para-exame',
    name: ROUTE_AUDIOS_EXAM,
    component: AudioSearchExam
  }, {
    path: '/audios/:id/revisao',
    name: ROUTE_AUDIO_REVIEW,
    component: AudioReview
  }, {
    path: '/audios/cadastrar',
    name: ROUTE_AUDIOS_NEW,
    component: AudioForm
  }, {
    path: '/usuarios',
    name: ROUTE_USERS_SEARCH,
    component: UserSearch
  }, {
    path: '/paginas-ensinos',
    name: ROUTE_LEARN_PAGES,
    component: LearnPageSearch
  }, {
    path: '/paginas-ensinos/adicionar',
    name: ROUTE_LEARN_PAGES_NEW,
    component: LearnPageForm
  }, {
    path: '/paginas-ensinos/:id/editar',
    name: ROUTE_LEARN_PAGES_EDIT,
    component: LearnPageForm
  }, {
    path: '/paginas-ensinos/visualizar/:id/:descricao',
    name: ROUTE_LEARN_PAGES_VIEW,
    component: LearnPageView
  }, {
    path: '/paginas-ensinos/pre-visualizar',
    name: ROUTE_LEARN_PAGES_TMP,
    component: LearnPageView
  }, {
    path: '/atualizacoes/cadastrar',
    name: ROUTE_UPDATES_NEW,
    component: UpdatesForm
  }, {
    path: '/atualizacoes/:id/editar',
    name: ROUTE_UPDATES_EDIT,
    component: UpdatesForm
  }, {
    path: '/atualizacoes/',
    name: ROUTE_UPDATES_LIST,
    component: UpdatesList
  }

  // {
  //   path: '/relatorios/faixas',
  //   name: ROUTE_REPORT_AUDIOS,
  //   component: AudiosReport
  // }, {
  //   path: '/relatorios/pesquisas',
  //   name: ROUTE_REPORT_SEARCH,
  //   component: SearchReport
  // }, {
  //   path: '/relatorios/acessos',
  //   name: ROUTE_REPORT_ACCESS,
  //   component: AccessReport
  // }, {
  //   path: '/relatorios/navegacao',
  //   name: ROUTE_REPORT_NAVIGATION,
  //   component: NavigationReport
  // }, {
  //   path: '/relatorios/revisoes',
  //   name: ROUTE_REPORT_REVIEWS,
  //   component: ReviewReport
  // }
  ]
}
