import { requests } from '@/plugins/Amplify'

import {
  STATUS_SAVED,
  STATUS_LOADED,
  STATUS_LOADING
} from '@/constants'

const UPDATE_ERROR = 'UPDATE_ERROR'
const UPDATES_LOADING = 'UPDATES_LOADING'
const UPDATE_SAVED_SUCCESS = 'UPDATE_SAVED_SUCCESS'
const UPDATE_DELETED_SUCCESS = 'UPDATE_DELETED_SUCCESS'
const UPDATE_LOADED_SUCCESS = 'UPDATE_LOADED_SUCCESS'
const UPDATE_LIST_LOADED_SUCCESS = 'UPDATE_LIST_LOADED_SUCCESS'

const resetStore = () => ({
  row: {},
  rows: [],
  count: 0,
  params: null,
  status: null
})

export default {
  state: resetStore(),

  actions: {
    loadUpdates: async ({ commit }, params) => {
      commit(UPDATES_LOADING, params)

      try {
        const data = await requests.getUpdates({ ...params, limit: 50 })
        commit(UPDATE_LIST_LOADED_SUCCESS, data)
      } catch (err) {
        console.log(err)
        commit(UPDATE_ERROR, err)
      }
    },

    saveUpdate: async ({ commit }, payload) => {
      commit(UPDATES_LOADING)

      try {
        const requestSave = payload.id ? requests.putUpdate(payload.id, payload) : requests.postUpdate(payload)
        const update = await requestSave
        commit(UPDATE_SAVED_SUCCESS, update)
      } catch (err) {
        console.log(err)
        commit(UPDATE_ERROR, err)
      }
    },

    loadUpdate: async ({ commit }, { id }) => {
      commit(UPDATES_LOADING)

      try {
        const update = await requests.getUpdate(id)
        commit(UPDATE_LOADED_SUCCESS, update)
      } catch (err) {
        console.log(err)
        commit(UPDATE_ERROR, err)
      }
    },

    removeUpdate: async ({ commit }, updateId) => {
      try {
        commit(UPDATES_LOADING)
        const data = await requests.deleteUpdate(updateId)
        commit(UPDATE_DELETED_SUCCESS, data)
      } catch (error) {
        commit(UPDATE_ERROR, error)
      }
    }
  },

  mutations: {
    [UPDATES_LOADING]: (state, params) => {
      const newState = { status: STATUS_LOADING }
      if (params) newState.params = params
      Object.assign(state, newState)
    },

    [UPDATE_LOADED_SUCCESS]: (state, row) => {
      Object.assign(state, { row, status: STATUS_LOADED })
    },

    [UPDATE_LIST_LOADED_SUCCESS]: (state, data) => {
      const { rows, count } = data
      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [UPDATE_SAVED_SUCCESS]: (state, row) => {
      Object.assign(state, { row, status: STATUS_SAVED })
    },

    [UPDATE_DELETED_SUCCESS]: (state, data) => {
      const rows = state.rows.filter(({ id }) => id !== data.id)
      Object.assign(state, { rows, status: STATUS_SAVED })
    },

    [UPDATE_ERROR]: (state, err) => {
      Object.assign(state, { row: { err } })
    }
  }
}
